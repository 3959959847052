import { useAppDispatch } from "app/store/store";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Category } from "services-hooks/types";
import styles from "./podcast-list.module.scss";
import Image from "next/image";
import MemoLogo from "../icons/Logo";
import { splitToChunks } from "app/utils/array";
import ArrowRight from "../icons/ArrowRight";
import { v4 } from "uuid";
import { useIntersection } from "react-use";
import { useRouter } from "next/router";
import { useGoToPodcast } from "app/hooks/navigation.hook";
import { calculatePodcastScroll } from "app/utils/scroll";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";
import { useFeaturesContext } from "app/context/features.context";

export interface PodcastListProps {
  items: Category[];
  label?: string;
  loading: boolean;
  rows?: number;
  className?: string;
  itemClassName?: string;
  fetchNextPage?: VoidFunction;
}

export const PodcastList: React.FC<PodcastListProps> = ({
  items,
  label,
  loading,
  className,
  itemClassName,
  rows = 1,
  fetchNextPage = () => {},
}) => {
  const [scrollSyncId] = useState(() => v4());
  const podcastListRef = useRef<HTMLDivElement>(null);
  const elementsRef = useRef<HTMLDivElement[]>([]);
  const loadMoreTriggerRef = useRef(null);
  const goToPodcast = useGoToPodcast();
  const { arrowNavigationEnabled, showSwimlaneArrows } = useFeaturesContext();
  const intersection = useIntersection(loadMoreTriggerRef, {
    rootMargin: "0px",
    threshold: 1,
  });
  useEffect(() => {
    if (intersection?.isIntersecting) {
      fetchNextPage();
    }
  }, [intersection]);
  const getRowCount = (items: Category[], rows: number): number => {
    //@TODO: change the logic
    if (rows === 3) {
      if (items.length > 10) {
        return 3;
      }
      return items.length > 5 ? 2 : 1;
    }
    return 1;
  };

  const isMobile = useIsMobileResolution();

  const itemRows = splitToChunks(items, getRowCount(items, rows));
  const onForward = () => {
    podcastListRef.current?.scrollTo({
      left: calculatePodcastScroll(
        podcastListRef.current.scrollLeft,
        false,
        isMobile
      ),
      behavior: "smooth",
    });
  };
  const onBackward = () => {
    podcastListRef.current?.scrollTo({
      left: calculatePodcastScroll(
        podcastListRef.current.scrollLeft,
        true,
        isMobile
      ),
      behavior: "smooth",
    });
  };

  const shouldShowArrrows = arrowNavigationEnabled ? showSwimlaneArrows : true;

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.header}>
        {<h2>{label}</h2>}
        {shouldShowArrrows && (
          <div className={styles.buttons}>
            <button
              className={clsx(styles.arrow, styles.arrow_back)}
              onClick={() => onBackward()}
            >
              <ArrowRight />
            </button>
            <button className={styles.arrow} onClick={() => onForward()}>
              <ArrowRight />
            </button>
          </div>
        )}
      </div>
      <div className={styles.podcast_list} ref={podcastListRef}>
        {loading ? (
          <div className={styles.row}>
            <div className={clsx(styles.podcast, styles.placeholder_item)} />
            <div className={clsx(styles.podcast, styles.placeholder_item)} />
            <div className={clsx(styles.podcast, styles.placeholder_item)} />
            <div className={clsx(styles.podcast, styles.placeholder_item)} />
            <div className={clsx(styles.podcast, styles.placeholder_item)} />
            <div className={clsx(styles.podcast, styles.placeholder_item)} />
          </div>
        ) : (
          itemRows.map((row, rowIndex) => (
            <div
              className={styles.row}
              key={`podcasts-row-${rowIndex}`}
              data-navigate-row
              data-navigate-scrollable
              data-navigate-scrollable-parent
              data-sync-scroll-position={scrollSyncId}
            >
              {row.map((podcast, index) => (
                <div
                  data-navigate-item
                  tabIndex={0}
                  className={clsx(styles.podcast, itemClassName)}
                  onClick={() => goToPodcast(`/${podcast.permalink}`)}
                  key={`podcast-${rowIndex}-${index}`}
                  ref={(e) => {
                    if (e) {
                      elementsRef.current[index] = e;
                    }
                  }}
                >
                  <div className={clsx(styles.podcast_img)}>
                    {podcast?.logo || podcast?.smallLogo ? (
                      <div className={styles.cover_wrapper}>
                        <Image
                          className={styles.cover}
                          key={`podcast-${index}`}
                          src={podcast?.logo || podcast?.smallLogo || ""}
                          layout="fill"
                          objectFit="cover"
                          alt=""
                          unoptimized
                        />
                      </div>
                    ) : (
                      <div
                        className={clsx(styles.cover, styles.no_image)}
                        onClick={() => goToPodcast(`/${podcast.permalink}`)}
                      >
                        <MemoLogo />
                      </div>
                    )}
                  </div>
                  <div className={styles.podcast_details}>
                    <h3 className={styles.podcast_name}>{podcast.name}</h3>
                    <div className={styles.podcast_description}>
                      {podcast.description}
                    </div>
                  </div>
                </div>
              ))}
              {rowIndex === 0 && <div ref={loadMoreTriggerRef} />}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
