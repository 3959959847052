import { useMedia } from "react-use";

export const useIsMobileResolution = () => {
  return useMedia('(max-width: 768px)');
}

export const useIsSmallDesktopResolution = () => {
  return useMedia('(max-width: 1281px)');
}

export const useIsLargeDesktopResolution = () => {
  return useMedia('(min-width: 1920px)');
}